@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?m375lv');
  src:  url('fonts/icomoon.eot?m375lv#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?m375lv') format('truetype'),
    url('fonts/icomoon.woff?m375lv') format('woff'),
    url('fonts/icomoon.svg?m375lv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-join:before {
  content: "\e910";
}
.icon-menu-close:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-alphabetical-divider:before {
  content: "\e900";
}
.icon-arrow-back:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-line:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-dot-list:before {
  content: "\e905";
}
.icon-menu-giving-list:before {
  content: "\e906";
}
.icon-menu-history:before {
  content: "\e907";
}
.icon-menu-home:before {
  content: "\e908";
}
.icon-menu-join1:before {
  content: "\e909";
}
.icon-menu-legacy-society-members:before {
  content: "\e90a";
}
.icon-menu-lifelegacy-program:before {
  content: "\e90b";
}
.icon-menu-partners:before {
  content: "\e90c";
}
.icon-menu-video:before {
  content: "\e90d";
}
.icon-scroll:before {
  content: "\e90e";
}
.icon-video-play:before {
  content: "\e90f";
}
