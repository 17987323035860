@charset "UTF-8";
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Extra Bold"), local("OpenSans-Extra-Bold"), url("fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Extra Bold Italic"), local("OpenSans-Extra-Bold-Italic"), url("fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Bold Italic"), local("OpenSans-Bold-Italic"), url("fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Semi Bold"), local("OpenSans-Semi-Bold"), url("fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Semi Bold Italic"), local("OpenSans-Semi-Bold-Italic"), url("fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Light"), local("OpenSans-Light"), url("fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Light Italic"), local("OpenSans-Light-Italic"), url("fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "aparajita";
  src: url("./fonts/aparajita.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "baskerville";
  src: url("./fonts/baskerville.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?m375lv");
  src: url("fonts/icomoon.eot?m375lv#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?m375lv") format("truetype"), url("fonts/icomoon.woff?m375lv") format("woff"), url("fonts/icomoon.svg?m375lv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-join:before {
  content: "";
}

.icon-menu-close:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-alphabetical-divider:before {
  content: "";
}

.icon-arrow-back:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-line:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-dot-list:before {
  content: "";
}

.icon-menu-giving-list:before {
  content: "";
}

.icon-menu-history:before {
  content: "";
}

.icon-menu-home:before {
  content: "";
}

.icon-menu-join1:before {
  content: "";
}

.icon-menu-legacy-society-members:before {
  content: "";
}

.icon-menu-lifelegacy-program:before {
  content: "";
}

.icon-menu-partners:before {
  content: "";
}

.icon-menu-video:before {
  content: "";
}

.icon-scroll:before {
  content: "";
}

.icon-video-play:before {
  content: "";
}

html,
body {
  min-height: 100%;
}

body {
  font: 400 16px/26px "Open Sans";
  color: #1E1D28;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  max-width: 1060px;
  padding-left: 99px;
}
@media (max-width: 1279px) {
  .container {
    padding-left: 120px;
    max-width: 904px;
  }
}
@media (max-width: 1023px) {
  .container {
    padding: 48px 24px;
    max-width: none;
  }
}

.with-bg-block {
  position: relative;
  min-height: 100vh;
}
.with-bg-block:after {
  content: "";
  position: absolute;
  width: 42%;
  height: 100%;
  background-color: #EDEDEE;
  right: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 1023px) {
  .with-bg-block:after {
    display: none;
  }
}

.title {
  font: 400 60px/54px "aparajita";
  margin: 0 0 24px;
}
@media (max-width: 1023px) {
  .title {
    font-size: 52px;
    line-height: 48px;
  }
}

.section {
  display: flex;
}
@media (max-width: 1023px) {
  .section {
    display: block;
  }
}
.section:not(:last-child) {
  margin-bottom: 205px;
}
@media (max-width: 1023px) {
  .section:not(:last-child) {
    margin-bottom: 100px;
  }
}
.section__title {
  display: block;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  color: #8F8E94;
  margin-bottom: 24px;
}
.section__content-left {
  width: 360px;
  flex-shrink: 0;
  margin-right: 140px;
}
@media (max-width: 1279px) {
  .section__content-left {
    width: 300px;
    margin-right: 100px;
  }
}
@media (max-width: 1023px) {
  .section__content-left {
    width: auto;
    margin: 0 0 70px;
  }
}
.section__content-right {
  width: 100%;
}

.media-content {
  padding: 24px;
  background-color: #fff;
  box-shadow: 44px 44px 84px rgba(10, 18, 48, 0.08);
}
@media (max-width: 1023px) {
  .media-content {
    padding: 0;
    box-shadow: none;
  }
}

.link {
  color: #00A4E4;
  text-decoration: none;
  cursor: pointer;
  transition: 0.25s;
}
.link:hover {
  color: #008ABF;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}

.hide {
  opacity: 0;
}

.show {
  opacity: 1;
}

.overflow-hidden {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.with-bg-block:after {
  opacity: 0;
}

.sidebar:after {
  height: 0;
}
.sidebar > * {
  opacity: 0;
}
.sidebar .nav__active-line {
  opacity: 0;
}
@media (max-width: 1279px) {
  .sidebar > * {
    opacity: 1;
  }
}

.section__content-left, .section__content-right,
.section .join-us__detail,
.section .join-us__form {
  opacity: 0;
}

.home__content {
  opacity: 0;
}
.home__images {
  opacity: 0;
}

.users-detail__videos {
  opacity: 0;
}

.alphabet-scroll__bar {
  opacity: 0;
}

.legacy-society-members__items,
.life-legacy-program__items {
  opacity: 1;
  animation: none !important;
}
.legacy-society-members__items .alphabet-scroll__content, .legacy-society-members__items .alphabet-scroll__bar,
.life-legacy-program__items .alphabet-scroll__content,
.life-legacy-program__items .alphabet-scroll__bar {
  opacity: 0;
}

.app-loaded .with-bg-block:after {
  animation: fadeInBgBlock 0.4s linear forwards;
}
@keyframes fadeInBgBlock {
  0% {
    opacity: 0;
    box-shadow: none;
  }
  40%, 70% {
    opacity: 1;
    box-shadow: none;
  }
  100% {
    opacity: 1;
    box-shadow: inset 5px 8px 84px rgba(10, 18, 48, 0.08);
  }
}
.app-loaded .sidebar:after {
  animation: slideDownLine 0.6s linear forwards;
}
@keyframes slideDownLine {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}
.app-loaded .sidebar > * {
  animation: fadeSlideUpSidebar 0.6s linear 0.6s forwards;
}
@media (max-width: 1279px) {
  .app-loaded .sidebar > * {
    animation: none;
  }
}
@keyframes fadeSlideUpSidebar {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.app-loaded .sidebar .nav__active-line {
  animation: fadeIn 0.6s linear 0.8s forwards;
}
.app-loaded .section__content-left,
.app-loaded .section .join-us__detail {
  animation: fadeSlideUpContent 0.6s linear 1.2s forwards;
}
.app-loaded .section__content-right,
.app-loaded .section .legacy-society-members__items .alphabet-scroll__content,
.app-loaded .section .life-legacy-program__items .alphabet-scroll__content,
.app-loaded .section .join-us__form {
  animation: fadeSlideUpContent 0.6s linear 1.8s forwards;
}
.app-loaded .section__with-images {
  animation-duration: 1.2s;
}
.app-loaded .users-detail__videos {
  animation: fadeSlideUpContent 0.6s linear 2.4s forwards;
}
.app-loaded .alphabet-scroll__bar {
  animation: fadeIn 0.6s linear 2.4s forwards;
}
.app-loaded .show {
  animation: fadeSlideUpContent 0.6s linear forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeSlideUpContent {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.page-loaded .sidebar > * {
  animation-delay: 0s;
}
.page-loaded .section__content-left,
.page-loaded .section .join-us__detail {
  animation-delay: 0.6s;
}
.page-loaded .section__content-right,
.page-loaded .section .legacy-society-members__items .alphabet-scroll__content,
.page-loaded .section .life-legacy-program__items .alphabet-scroll__content,
.page-loaded .section .join-us__form {
  animation-delay: 1.2s;
}
.page-loaded .users-detail__videos {
  animation-delay: 1.8s;
}
.page-loaded .alphabet-scroll__bar {
  animation: fadeIn 0.6s linear 1.8s forwards;
}