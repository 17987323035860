// BLOCK DEFAULT STATE
.with-bg-block {
  &:after {
    opacity: 0;
  }
}

.sidebar {
  &:after {
    height: 0;
  }

  &>* {
    opacity: 0;
  }

  .nav {
    &__active-line {
      opacity: 0;
    }
  }

  @media (max-width: map-get($breakpoints, 'md')) {
    &>* {
      opacity: 1;
    }
  }
}

.section {

  &__content-left,
  &__content-right,
  .join-us__detail,
  .join-us__form {
    opacity: 0;
  }
}

.home {
  &__content {
    opacity: 0;
  }

  &__images {
    opacity: 0;
  }
}

.users-detail {
  &__videos {
    opacity: 0;
  }
}

.alphabet-scroll {
  &__bar {
    opacity: 0;
  }
}

.legacy-society-members__items,
.life-legacy-program__items {
  opacity: 1;
  animation: none !important;

  .alphabet-scroll {

    &__content,
    &__bar {
      opacity: 0;
    }
  }
}

// ANIMATION AFTER APP LOADED
.app-loaded {
  .with-bg-block {
    &:after {
      animation: fadeInBgBlock .4s linear forwards;

      @keyframes fadeInBgBlock {
        0% {
          opacity: 0;
          box-shadow: none;
        }

        40%,
        70% {
          opacity: 1;
          box-shadow: none;
        }

        100% {
          opacity: 1;
          box-shadow: inset 5px 8px 84px rgb(10 18 48 / 8%);
        }
      }
    }
  }

  .sidebar {
    &:after {
      animation: slideDownLine .6s linear forwards;

      @keyframes slideDownLine {
        0% {
          height: 0;
        }

        100% {
          height: 100vh;
        }
      }
    }

    &>* {
      animation: fadeSlideUpSidebar .6s linear #{.6s} forwards;

      @media (max-width: map-get($breakpoints, 'md')) {
        animation: none;
      }

      @keyframes fadeSlideUpSidebar {
        0% {
          opacity: 0;
          transform: translateY(20px);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .nav {
      &__active-line {
        animation: fadeIn .6s linear #{.8s} forwards;
      }
    }
  }

  .section {
    &__content-left,
    .join-us__detail {
      animation: fadeSlideUpContent .6s linear #{.6s * 2} forwards;
    }

    &__content-right,
    .legacy-society-members__items .alphabet-scroll__content,
    .life-legacy-program__items .alphabet-scroll__content,
    .join-us__form {
      animation: fadeSlideUpContent .6s linear #{.6s * 3} forwards;
    }

    &__with-images {
      animation-duration: 1.2s;
    }
  }

  .users-detail {
    &__videos {
      animation: fadeSlideUpContent .6s linear #{.6s * 4} forwards;
    }
  }

  .alphabet-scroll {
    &__bar {
      animation: fadeIn .6s linear #{2.4s} forwards;
    }
  }

  .show {
    animation: fadeSlideUpContent .6s linear forwards;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeSlideUpContent {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


// ANIMATION AFTER PAGE LOADED
.page-loaded {
  .sidebar > * {
    animation-delay: 0s;
  }
  .section {
    &__content-left,
    .join-us__detail {
      animation-delay: #{.6s};
    }

    &__content-right,
    .legacy-society-members__items .alphabet-scroll__content,
    .life-legacy-program__items .alphabet-scroll__content,
    .join-us__form {
      animation-delay: #{.6s * 2};
    }
  }

  .users-detail {
    &__videos {
      animation-delay: #{.6s * 3};
    }
  }

  .alphabet-scroll {
    &__bar {
      animation: fadeIn .6s linear #{.6s * 3} forwards;
    }
  }
}
