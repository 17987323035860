html,
body {
  min-height: 100%;
}

body {
  font: 400 16px/26px 'Open Sans';
  color: #1E1D28;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  max-width: 1060px;
  padding-left: 99px;

  @media (max-width: map-get($breakpoints, 'md')) {
    padding-left: 120px;
    max-width: 904px;
  }

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 48px 24px;
    max-width: none;
  }
}

.with-bg-block {
  position: relative;
  min-height: 100vh;

  &:after {
    content: '';
    position: absolute;
    width: 42%;
    height: 100%;
    background-color: #EDEDEE;
    right: 0;
    top: 0;
    z-index: -1;
  }

  @media (max-width: map-get($breakpoints, 'sm')) {
    &:after {
      display: none;
    }
  }
}

.title {
  font: 400 60px/54px 'aparajita';
  margin: 0 0 24px;

  @media (max-width: map-get($breakpoints, 'sm')) {
    font-size: 52px;
    line-height: 48px;
  }
}

.section {
  display: flex;

  @media (max-width: map-get($breakpoints, 'sm')) {
    display: block;
  }

  &:not(:last-child) {
    margin-bottom: 205px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-bottom: 100px;
    }
  }

  &__title {
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.65px;
    text-transform: uppercase;
    color: #8F8E94;
    margin-bottom: 24px;
  }

  &__content-left {
    width: 360px;
    flex-shrink: 0;
    margin-right: 140px;

    @media (max-width: map-get($breakpoints, 'md')) {
      width: 300px;
      margin-right: 100px;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: auto;
      margin: 0 0 70px;
    }
  }

  &__content-right {
    width: 100%;
  }
}

.media-content {
  padding: 24px;
  background-color: #fff;
  box-shadow: 44px 44px 84px rgba(10, 18, 48, 0.08);

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 0;
    box-shadow: none;
  }
}

.link {
  color: #00A4E4;
  text-decoration: none;
  cursor: pointer;
  transition: .25s;

  &:hover {
    color: #008ABF;
  }
}

.d {
  &-none {
    display: none;
  }

  &-block {
    display: block;
  }

  &-flex {
    display: flex;
  }
}

.hide {
  opacity: 0;
}

.show {
  opacity: 1;
}

.overflow-hidden {
  overflow: hidden;
}


* {
  box-sizing: border-box;
}
